import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=7e4c1829&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=ts&"
export * from "./Header.vue?vue&type=script&lang=ts&"
import style0 from "./Header.vue?vue&type=style&index=0&id=7e4c1829&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e4c1829",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutHeaderNotifications: require('/home/cleavr/www.bchollos.es/releases/20241011093225269/components/layout/header/Notifications.vue').default,LayoutHeaderAdBars: require('/home/cleavr/www.bchollos.es/releases/20241011093225269/components/layout/header/AdBars.vue').default,LayoutHeaderMenu: require('/home/cleavr/www.bchollos.es/releases/20241011093225269/components/layout/header/Menu.vue').default,LayoutHeaderSearch: require('/home/cleavr/www.bchollos.es/releases/20241011093225269/components/layout/header/Search.vue').default,LayoutHeaderUser: require('/home/cleavr/www.bchollos.es/releases/20241011093225269/components/layout/header/User.vue').default,LayoutHeaderArcLinks: require('/home/cleavr/www.bchollos.es/releases/20241011093225269/components/layout/header/ArcLinks.vue').default})
