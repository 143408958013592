import { Context } from '@nuxt/types'

export default function (ctx: Context) {
    if (ctx.store.state.auth.isAuth) {
        return
    }

    const pathsToMAtch = [
        `/${ctx.$util.lang.routes.share}/${ctx.$util.lang.routes.chat}`,
        `/${ctx.$util.lang.routes.share}/${ctx.$util.lang.routes.coupon}`,
        `/${ctx.$util.lang.routes.share}/${ctx.$util.lang.routes.offer}`,
    ]

    if (ctx.route.path && pathsToMAtch.find((path) => path === ctx.route.path)) {
        ctx.store.commit('setModal', { name: 'ModalAuthLogin' })
        ctx.redirect(302, { path: `/${ctx.$util.lang.routes.share}` })
        return
    }

    ctx.error({
        statusCode: 401,
        message: ctx.$util.lang.middleware.ifAuthenticated.text,
    })
}
