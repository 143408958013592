import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { AxiosError } from 'axios'
import endpoints from '@/api/endpoints/pages'

export default (AxiosInstance: NuxtAxiosInstance) => ({
    loadHome: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.home.path, { params: query }).catch((e: AxiosError) => e.response?.data),
    loadHomeFeatured: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.home.featured, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadHomeCommented: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.home.commented, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadHomeLatest: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.home.latest, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadCoupons: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.coupons.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadOffers: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.offers.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadLotteries: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.lotteries.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    gatStoreLanding: (slug: string) =>
        AxiosInstance.$get(endpoints.store.landing.replace('_SLUG_', slug)).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadLottery: (slug: string) =>
        AxiosInstance.$get(endpoints.lottery.path + '/' + slug).catch((e: AxiosError) => e.response?.data),
    loadOffer: (slug: string) =>
        AxiosInstance.$get(endpoints.offer.path + '/' + slug).catch((e: AxiosError) => e.response?.data),
    loadCoupon: (slug: string) =>
        AxiosInstance.$get(endpoints.coupon.path + '/' + slug).catch((e: AxiosError) => e.response?.data),
    loadForum: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.forum.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadThread: (slug: string, query?: object | undefined) =>
        AxiosInstance.$get(endpoints.thread.path + '/' + slug, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadNovelties: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.novelties.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadNovelty: (slug: string) =>
        AxiosInstance.$get(endpoints.novelty.path + '/' + slug).catch((e: AxiosError) => e.response?.data),
    loadBlog: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.blog.path, { params: query }).catch((e: AxiosError) => e.response?.data),
    loadArticlesByCategory: (category: string, query?: object | undefined) =>
        AxiosInstance.$get(endpoints.articlesByCategory.path + '/' + category, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadArticle: (slug: string) =>
        AxiosInstance.$get(endpoints.articles.path + '/' + slug).catch((e: AxiosError) => e.response?.data),
    loadCategories: () =>
        AxiosInstance.$get(endpoints.categories.path).catch((e: AxiosError) => e.response?.data),
    loadCategory: (slug: string, query?: object) =>
        AxiosInstance.$get(endpoints.category.path + '/' + slug, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadSecondaryDataOfCategory: (slug: string, query?: object) =>
        AxiosInstance.$get(endpoints.category.secondaryData.replace('_SLUG_', slug), { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadFree: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.free.path, { params: query }).catch((e: AxiosError) => e.response?.data),
    loadEvents: (query: object | undefined) =>
        AxiosInstance.$get(endpoints.events.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadEvent: (slug: string, query?: object | undefined) =>
        AxiosInstance.$get(endpoints.event.path + '/' + slug, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadStores: () => AxiosInstance.$get(endpoints.stores.path).catch((e: AxiosError) => e.response?.data),
    loadStore: (slug: string, query?: object) =>
        AxiosInstance.$get(endpoints.store.path.replace('_SLUG_', slug), { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    gatStoreLPStatus: (slug: string) =>
        AxiosInstance.$get(endpoints.store.path.replace('_SLUG_', slug)).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadSecondaryDataOfStore: (slug: string) =>
        AxiosInstance.$get(endpoints.store.secondaryData.replace('_SLUG_', slug)).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadSearch: (query: object) =>
        AxiosInstance.$get(endpoints.search.path, { params: query }).catch(
            (e: AxiosError) => e.response?.data
        ),
    loadUserProfile: (id: string) => AxiosInstance.$get(endpoints.user.path.replace('_ID_', id)),
    loadLogin: () => AxiosInstance.$get(endpoints.login.path),
    loadRegister: () => AxiosInstance.$get(endpoints.register.path),
    loadSitemap: (slug: keyof typeof endpoints.siteMap, params?: object) =>
        AxiosInstance.$get(endpoints.siteMap[slug], { params }).catch((e: AxiosError) => e.response?.data),
})
