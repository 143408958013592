import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _015a22be = () => interopDefault(import('../pages/accessibility.vue' /* webpackChunkName: "pages/accessibility" */))
const _3f4d26b4 = () => interopDefault(import('../pages/acerca-de.vue' /* webpackChunkName: "pages/acerca-de" */))
const _267e9fef = () => interopDefault(import('../pages/aviso-legal.vue' /* webpackChunkName: "pages/aviso-legal" */))
const _46944ecd = () => interopDefault(import('../pages/ayuda.vue' /* webpackChunkName: "pages/ayuda" */))
const _3d447462 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _cb0a73ae = () => interopDefault(import('../pages/cambiar-contrasena.vue' /* webpackChunkName: "pages/cambiar-contrasena" */))
const _6fe5fb4c = () => interopDefault(import('../pages/ccpa-privacy-notice.vue' /* webpackChunkName: "pages/ccpa-privacy-notice" */))
const _74ce008e = () => interopDefault(import('../views/StoresBridge.vue' /* webpackChunkName: "" */))
const _0c1e22be = () => interopDefault(import('../views/Homepage.vue' /* webpackChunkName: "" */))
const _00cd12f3 = () => interopDefault(import('../pages/compartir/index.vue' /* webpackChunkName: "pages/compartir/index" */))
const _294c18cc = () => interopDefault(import('../pages/contacto.vue' /* webpackChunkName: "pages/contacto" */))
const _bba6eaa0 = () => interopDefault(import('../pages/contrasena-actualizada-exitosamente.vue' /* webpackChunkName: "pages/contrasena-actualizada-exitosamente" */))
const _1812e0bc = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _c14b8dbe = () => interopDefault(import('../pages/cupones/index.vue' /* webpackChunkName: "pages/cupones/index" */))
const _072ef7aa = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _76ecd2a6 = () => interopDefault(import('../pages/dashboard/index/index.vue' /* webpackChunkName: "pages/dashboard/index/index" */))
const _2fc1d1d0 = () => interopDefault(import('../pages/dashboard/index/actividad.vue' /* webpackChunkName: "pages/dashboard/index/actividad" */))
const _610676d6 = () => interopDefault(import('../pages/dashboard/index/ajustes.vue' /* webpackChunkName: "pages/dashboard/index/ajustes" */))
const _56477976 = () => interopDefault(import('../pages/dashboard/index/chats.vue' /* webpackChunkName: "pages/dashboard/index/chats" */))
const _33656d80 = () => interopDefault(import('../pages/dashboard/index/descuentos.vue' /* webpackChunkName: "pages/dashboard/index/descuentos" */))
const _002898c7 = () => interopDefault(import('../pages/dashboard/index/guardados.vue' /* webpackChunkName: "pages/dashboard/index/guardados" */))
const _0fc779b8 = () => interopDefault(import('../pages/dashboard/index/monedas.vue' /* webpackChunkName: "pages/dashboard/index/monedas" */))
const _57533384 = () => interopDefault(import('../pages/dashboard/index/premios.vue' /* webpackChunkName: "pages/dashboard/index/premios" */))
const _41311b46 = () => interopDefault(import('../pages/dashboard/index/sorteos.vue' /* webpackChunkName: "pages/dashboard/index/sorteos" */))
const _a5ab3e10 = () => interopDefault(import('../views/CategoriesBridge.vue' /* webpackChunkName: "" */))
const _d6ba15be = () => interopDefault(import('../pages/do-not-sell.vue' /* webpackChunkName: "pages/do-not-sell" */))
const _47f71554 = () => interopDefault(import('../pages/eventos/index.vue' /* webpackChunkName: "pages/eventos/index" */))
const _e9a34cc0 = () => interopDefault(import('../pages/expirados.vue' /* webpackChunkName: "pages/expirados" */))
const _25291bae = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _e4ced87a = () => interopDefault(import('../pages/foros/index.vue' /* webpackChunkName: "pages/foros/index" */))
const _79edc145 = () => interopDefault(import('../pages/gratis.vue' /* webpackChunkName: "pages/gratis" */))
const _102fe036 = () => interopDefault(import('../pages/inicio-sin-contrasena.vue' /* webpackChunkName: "pages/inicio-sin-contrasena" */))
const _b9e3f598 = () => interopDefault(import('../pages/interest-driven-deals.vue' /* webpackChunkName: "pages/interest-driven-deals" */))
const _38378f01 = () => interopDefault(import('../pages/jotadeals-terms-and-policies.vue' /* webpackChunkName: "pages/jotadeals-terms-and-policies" */))
const _0bb955c6 = () => interopDefault(import('../pages/legal-copyright.vue' /* webpackChunkName: "pages/legal-copyright" */))
const _07514d86 = () => interopDefault(import('../pages/legales/index.vue' /* webpackChunkName: "pages/legales/index" */))
const _893cfb88 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _9242c4d2 = () => interopDefault(import('../views/InstallExtension.vue' /* webpackChunkName: "" */))
const _79ae438b = () => interopDefault(import('../pages/mensajeria/index.vue' /* webpackChunkName: "pages/mensajeria/index" */))
const _20fd8787 = () => interopDefault(import('../pages/notificaciones.vue' /* webpackChunkName: "pages/notificaciones" */))
const _d0cdc7ca = () => interopDefault(import('../pages/novedades/index.vue' /* webpackChunkName: "pages/novedades/index" */))
const _17302387 = () => interopDefault(import('../pages/nueva-contrasena.vue' /* webpackChunkName: "pages/nueva-contrasena" */))
const _713bfeb8 = () => interopDefault(import('../pages/ofertas/index.vue' /* webpackChunkName: "pages/ofertas/index" */))
const _e9b42e90 = () => interopDefault(import('../pages/olvide-mi-contrasena.vue' /* webpackChunkName: "pages/olvide-mi-contrasena" */))
const _a09e0f36 = () => interopDefault(import('../pages/politicas-de-privacidad.vue' /* webpackChunkName: "pages/politicas-de-privacidad" */))
const _1c7aa895 = () => interopDefault(import('../views/Preferences.vue' /* webpackChunkName: "" */))
const _fa964b14 = () => interopDefault(import('../pages/prensa.vue' /* webpackChunkName: "pages/prensa" */))
const _e6ef52a0 = () => interopDefault(import('../pages/registro.vue' /* webpackChunkName: "pages/registro" */))
const _1c594ffc = () => interopDefault(import('../pages/reglas-de-comunidad.vue' /* webpackChunkName: "pages/reglas-de-comunidad" */))
const _1d7e4236 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _4024716a = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _057c698e = () => interopDefault(import('../pages/sitemap/index/index.vue' /* webpackChunkName: "pages/sitemap/index/index" */))
const _bdf4f07e = () => interopDefault(import('../pages/sitemap/index/promociones/index.vue' /* webpackChunkName: "pages/sitemap/index/promociones/index" */))
const _1f457137 = () => interopDefault(import('../pages/sitemap/index/tiendas/index.vue' /* webpackChunkName: "pages/sitemap/index/tiendas/index" */))
const _c162330e = () => interopDefault(import('../pages/sitemap/index/promociones/_slug.vue' /* webpackChunkName: "pages/sitemap/index/promociones/_slug" */))
const _0d1cc21d = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _34d41164 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _18e59387 = () => interopDefault(import('../pages/verificar-cuenta.vue' /* webpackChunkName: "pages/verificar-cuenta" */))
const _9bf19772 = () => interopDefault(import('../pages/compartir/chat.vue' /* webpackChunkName: "pages/compartir/chat" */))
const _acbfb57e = () => interopDefault(import('../pages/compartir/cupon/index.vue' /* webpackChunkName: "pages/compartir/cupon/index" */))
const _9a714158 = () => interopDefault(import('../pages/compartir/finalizado.vue' /* webpackChunkName: "pages/compartir/finalizado" */))
const _9ab0c13a = () => interopDefault(import('../pages/compartir/oferta/index.vue' /* webpackChunkName: "pages/compartir/oferta/index" */))
const _3a657c12 = () => interopDefault(import('../pages/compartir/sorteo.vue' /* webpackChunkName: "pages/compartir/sorteo" */))
const _056eeb14 = () => interopDefault(import('../pages/compartir/cupon/crear.vue' /* webpackChunkName: "pages/compartir/cupon/crear" */))
const _06500498 = () => interopDefault(import('../pages/compartir/oferta/crear.vue' /* webpackChunkName: "pages/compartir/oferta/crear" */))
const _c608c06c = () => interopDefault(import('../views/StoreMask.vue' /* webpackChunkName: "" */))
const _31078130 = () => interopDefault(import('../pages/blog/_category/index.vue' /* webpackChunkName: "pages/blog/_category/index" */))
const _07c00c5e = () => interopDefault(import('../views/DiscountDetails.vue' /* webpackChunkName: "" */))
const _2cd3c782 = () => interopDefault(import('../pages/dashboard/_user.vue' /* webpackChunkName: "pages/dashboard/_user" */))
const _294db6f6 = () => interopDefault(import('../pages/dashboard/_user/index.vue' /* webpackChunkName: "pages/dashboard/_user/index" */))
const _43780e20 = () => interopDefault(import('../pages/dashboard/_user/actividad.vue' /* webpackChunkName: "pages/dashboard/_user/actividad" */))
const _7d17074e = () => interopDefault(import('../pages/dashboard/_user/chats.vue' /* webpackChunkName: "pages/dashboard/_user/chats" */))
const _9676bb30 = () => interopDefault(import('../pages/dashboard/_user/descuentos.vue' /* webpackChunkName: "pages/dashboard/_user/descuentos" */))
const _4640740c = () => interopDefault(import('../pages/eventos/_slug.vue' /* webpackChunkName: "pages/eventos/_slug" */))
const _e83c1b0a = () => interopDefault(import('../pages/foros/_slug.vue' /* webpackChunkName: "pages/foros/_slug" */))
const _b8090d00 = () => interopDefault(import('../views/DiscountMask.vue' /* webpackChunkName: "" */))
const _0defe0e5 = () => interopDefault(import('../pages/landing/_slug.vue' /* webpackChunkName: "pages/landing/_slug" */))
const _0abe9016 = () => interopDefault(import('../pages/legales/_slug.vue' /* webpackChunkName: "pages/legales/_slug" */))
const _d43b0a5a = () => interopDefault(import('../pages/novedades/_slug.vue' /* webpackChunkName: "pages/novedades/_slug" */))
const _d989536e = () => interopDefault(import('../pages/blog/_category/_article.vue' /* webpackChunkName: "pages/blog/_category/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibility",
    component: _015a22be,
    name: "accessibility"
  }, {
    path: "/acerca-de",
    component: _3f4d26b4,
    name: "acerca-de"
  }, {
    path: "/aviso-legal",
    component: _267e9fef,
    name: "aviso-legal"
  }, {
    path: "/ayuda",
    component: _46944ecd,
    name: "ayuda"
  }, {
    path: "/blog",
    component: _3d447462,
    name: "blog"
  }, {
    path: "/cambiar-contrasena",
    component: _cb0a73ae,
    name: "cambiar-contrasena"
  }, {
    path: "/ccpa-privacy-notice",
    component: _6fe5fb4c,
    name: "ccpa-privacy-notice"
  }, {
    path: "/codigos-descuento",
    component: _74ce008e,
    name: "codigos-descuento"
  }, {
    path: "/comentados",
    component: _0c1e22be,
    name: "index-comentados"
  }, {
    path: "/compartir",
    component: _00cd12f3,
    name: "compartir"
  }, {
    path: "/contacto",
    component: _294c18cc,
    name: "contacto"
  }, {
    path: "/contrasena-actualizada-exitosamente",
    component: _bba6eaa0,
    name: "contrasena-actualizada-exitosamente"
  }, {
    path: "/cookies",
    component: _1812e0bc,
    name: "cookies"
  }, {
    path: "/cupones",
    component: _c14b8dbe,
    name: "cupones"
  }, {
    path: "/dashboard",
    component: _072ef7aa,
    children: [{
      path: "",
      component: _76ecd2a6,
      name: "dashboard-index"
    }, {
      path: "actividad",
      component: _2fc1d1d0,
      name: "dashboard-index-actividad"
    }, {
      path: "ajustes",
      component: _610676d6,
      name: "dashboard-index-ajustes"
    }, {
      path: "chats",
      component: _56477976,
      name: "dashboard-index-chats"
    }, {
      path: "descuentos",
      component: _33656d80,
      name: "dashboard-index-descuentos"
    }, {
      path: "guardados",
      component: _002898c7,
      name: "dashboard-index-guardados"
    }, {
      path: "monedas",
      component: _0fc779b8,
      name: "dashboard-index-monedas"
    }, {
      path: "premios",
      component: _57533384,
      name: "dashboard-index-premios"
    }, {
      path: "sorteos",
      component: _41311b46,
      name: "dashboard-index-sorteos"
    }]
  }, {
    path: "/descuentos",
    component: _a5ab3e10,
    name: "descuentos"
  }, {
    path: "/do-not-sell",
    component: _d6ba15be,
    name: "do-not-sell"
  }, {
    path: "/eventos",
    component: _47f71554,
    name: "eventos"
  }, {
    path: "/expirados",
    component: _e9a34cc0,
    name: "expirados"
  }, {
    path: "/faq",
    component: _25291bae,
    name: "faq"
  }, {
    path: "/foros",
    component: _e4ced87a,
    name: "foros"
  }, {
    path: "/gratis",
    component: _79edc145,
    name: "gratis"
  }, {
    path: "/inicio-sin-contrasena",
    component: _102fe036,
    name: "inicio-sin-contrasena"
  }, {
    path: "/interest-driven-deals",
    component: _b9e3f598,
    name: "interest-driven-deals"
  }, {
    path: "/jotadeals-terms-and-policies",
    component: _38378f01,
    name: "jotadeals-terms-and-policies"
  }, {
    path: "/legal-copyright",
    component: _0bb955c6,
    name: "legal-copyright"
  }, {
    path: "/legales",
    component: _07514d86,
    name: "legales"
  }, {
    path: "/login",
    component: _893cfb88,
    name: "login"
  }, {
    path: "/lp",
    component: _9242c4d2,
    name: "lp"
  }, {
    path: "/mensajeria",
    component: _79ae438b,
    name: "mensajeria"
  }, {
    path: "/notificaciones",
    component: _20fd8787,
    name: "notificaciones"
  }, {
    path: "/novedades",
    component: _d0cdc7ca,
    name: "novedades"
  }, {
    path: "/nueva-contrasena",
    component: _17302387,
    name: "nueva-contrasena"
  }, {
    path: "/nuevos",
    component: _0c1e22be,
    name: "index-nuevos"
  }, {
    path: "/ofertas",
    component: _713bfeb8,
    name: "ofertas"
  }, {
    path: "/olvide-mi-contrasena",
    component: _e9b42e90,
    name: "olvide-mi-contrasena"
  }, {
    path: "/politicas-de-privacidad",
    component: _a09e0f36,
    name: "politicas-de-privacidad"
  }, {
    path: "/preferencias",
    component: _1c7aa895,
    name: "preferences"
  }, {
    path: "/prensa",
    component: _fa964b14,
    name: "prensa"
  }, {
    path: "/registro",
    component: _e6ef52a0,
    name: "registro"
  }, {
    path: "/reglas-de-comunidad",
    component: _1c594ffc,
    name: "reglas-de-comunidad"
  }, {
    path: "/search",
    component: _1d7e4236,
    name: "search"
  }, {
    path: "/sitemap",
    component: _4024716a,
    children: [{
      path: "",
      component: _057c698e,
      name: "sitemap-index"
    }, {
      path: "promociones",
      component: _bdf4f07e,
      name: "sitemap-index-promociones"
    }, {
      path: "tiendas",
      component: _1f457137,
      name: "sitemap-index-tiendas"
    }, {
      path: "promociones/:slug?",
      component: _c162330e,
      name: "sitemap-index-promociones-slug"
    }]
  }, {
    path: "/subscribe",
    component: _0d1cc21d,
    name: "subscribe"
  }, {
    path: "/unsubscribe",
    component: _34d41164,
    name: "unsubscribe"
  }, {
    path: "/verificar-cuenta",
    component: _18e59387,
    name: "verificar-cuenta"
  }, {
    path: "/compartir/chat",
    component: _9bf19772,
    name: "compartir-chat"
  }, {
    path: "/compartir/cupon",
    component: _acbfb57e,
    name: "compartir-cupon"
  }, {
    path: "/compartir/finalizado",
    component: _9a714158,
    name: "compartir-finalizado"
  }, {
    path: "/compartir/oferta",
    component: _9ab0c13a,
    name: "compartir-oferta"
  }, {
    path: "/compartir/sorteo",
    component: _3a657c12,
    name: "compartir-sorteo"
  }, {
    path: "/compartir/cupon/crear",
    component: _056eeb14,
    name: "compartir-cupon-crear"
  }, {
    path: "/compartir/oferta/crear",
    component: _06500498,
    name: "compartir-oferta-crear"
  }, {
    path: "/codigos-descuento/letra/:slug",
    component: _74ce008e,
    name: "codigos-descuento-letra-slug"
  }, {
    path: "/descuentos/buscar/:slug",
    component: _a5ab3e10,
    name: "descuentos-buscar-slug"
  }, {
    path: "/go/codigos-descuento/:id",
    component: _c608c06c,
    name: "go-store-slug"
  }, {
    path: "/blog/:category",
    component: _31078130,
    name: "blog-category"
  }, {
    path: "/codigos-descuento/:slug",
    component: _74ce008e,
    name: "codigos-descuento-slug"
  }, {
    path: "/cupones/:slug",
    component: _07c00c5e,
    name: "cupones-slug"
  }, {
    path: "/dashboard/:user",
    component: _2cd3c782,
    children: [{
      path: "",
      component: _294db6f6,
      name: "dashboard-user"
    }, {
      path: "actividad",
      component: _43780e20,
      name: "dashboard-user-actividad"
    }, {
      path: "chats",
      component: _7d17074e,
      name: "dashboard-user-chats"
    }, {
      path: "descuentos",
      component: _9676bb30,
      name: "dashboard-user-descuentos"
    }]
  }, {
    path: "/descuentos/:slug",
    component: _a5ab3e10,
    name: "descuentos-slug"
  }, {
    path: "/eventos/:slug",
    component: _4640740c,
    name: "eventos-slug"
  }, {
    path: "/foros/:slug",
    component: _e83c1b0a,
    name: "foros-slug"
  }, {
    path: "/go/:id",
    component: _b8090d00,
    name: "go-id"
  }, {
    path: "/goprize/:hash",
    component: _b8090d00,
    name: "goprize-hash"
  }, {
    path: "/landing/:slug?",
    component: _0defe0e5,
    name: "landing-slug"
  }, {
    path: "/legales/:slug",
    component: _0abe9016,
    name: "legales-slug"
  }, {
    path: "/lp/:slug",
    component: _9242c4d2,
    name: "lp-slug"
  }, {
    path: "/novedades/:slug",
    component: _d43b0a5a,
    name: "novedades-slug"
  }, {
    path: "/ofertas/:slug",
    component: _07c00c5e,
    name: "ofertas-slug"
  }, {
    path: "/open/:slug",
    component: _b8090d00,
    name: "open-slug"
  }, {
    path: "/blog/:category/:article",
    component: _d989536e,
    name: "blog-category-article"
  }, {
    path: "/",
    component: _0c1e22be,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
